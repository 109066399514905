import { t } from "i18next";
import head from "assets/images/head.svg";
import wifi from "assets/images/wifi.svg";
import lens from "assets/images/lens.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Rankings = () => (
    <section tw="bg-gray-200 text-black px-[4rem] sm:px-[1rem] py-[7.5rem] sm:py-[3rem]">
        <div tw="max-w-[83rem] mx-auto">
            <h2 tw="font-bold text-[3.75rem] sm:text-[2.75rem] leading-[4rem] sm:leading-[2.5rem] w-full max-w-[30rem]">
                {t("rankings_title")}
            </h2>
            <div tw="grid grid-cols-[repeat(3, minmax(9rem, 21rem))] md:grid-cols-none mt-[3.3rem] gap-[4rem] place-content-between">
                <div tw="flex flex-col justify-between gap-[3rem]">
                    <div>
                        <img src={head} alt="Rankings" />
                        <h3 tw="font-semiBold text-[1.125rem] sm:text-sm leading-[1.5rem] m-[1.5rem 0 0.5rem]">
                            {t("rankings_subtitle_1")}
                        </h3>
                        <p tw="sm:text-sm leading-[1.3rem] sm:leading-[1.17rem]">
                            {t("rankings_text_1")}
                        </p>
                    </div>
                    <a
                        target="_blank"
                        href="/methodology.pdf"
                        tw="p-[0.625rem 1.5rem] rounded-lg bg-purple-600 hover:bg-purple-800 text-white text-sm uppercase max-w-[13rem] text-center"
                    >
                        {t("rankings_button")}
                    </a>
                </div>
                <div tw="flex flex-col justify-between gap-[3rem]">
                    <div>
                        <img src={wifi} alt="Rankings" />
                        <h3 tw="font-semiBold text-[1.125rem] sm:text-sm leading-[1.5rem] m-[1.5rem 0 0.5rem]">
                            {t("rankings_subtitle_2")}
                        </h3>
                        <p tw="sm:text-sm !leading-[1.3rem]">
                            {t("rankings_text_2_1")}
                            <br />
                            <strong>{t("rankings_text_2_2")}</strong>
                            {t("rankings_text_2_3")}
                            <br />
                            <strong>{t("rankings_text_2_4")}</strong>
                            {t("rankings_text_2_5")}
                            <br />
                            <strong>{t("rankings_text_2_6")}</strong>
                            {t("rankings_text_2_7")}
                        </p>
                    </div>
                </div>
                <div tw="flex flex-col justify-between gap-[3rem]">
                    <div>
                        <img src={lens} alt="Rankings" />
                        <h3 tw="font-semiBold text-[1.125rem] sm:text-sm leading-[1.5rem] m-[1.5rem 0 0.5rem]">
                            {t("rankings_subtitle_3")}
                        </h3>
                        <p tw="sm:text-sm leading-[1.3rem] sm:leading-[1.17rem]">
                            {t("rankings_text_3")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Rankings;
