import { Types } from "interfaces/Types.interface";

export const types: Types[] = [
    {
        value: "type_of_visualisation_map",
        id: "map"
    },
    {
        value: "type_of_visualisation_ranking",
        id: "ranking"
    }
];

export const rankingTypes: Types[] = [
    {
        value: "type_of_visualisation_index",
        id: "poverty"
    },
    {
        value: "type_of_visualisation_internet_pricing",
        id: "internet_pricing"
    }
];

export const genders: Types[] = [
    {
        value: "all_genders",
        id: "overall"
    },
    {
        value: "males",
        id: "males"
    },
    {
        value: "females",
        id: "females"
    }
];

export const ages: Types[] = [
    {
        value: "all_age_groups",
        id: "all"
    },
    {
        value: "zero_to_fifteen",
        id: "0-15"
    },
    {
        value: "fifteen_to_thirty",
        id: "15-30"
    },
    {
        value: "thirthy_to_fourtyfive",
        id: "30-45"
    },
    {
        value: "fourtyfive_to_sistyfive",
        id: "45-65"
    },
    {
        value: "older_then_sistyfive",
        id: "65"
    }
];

export const continents: Types[] = [
    {
        value: "select_continent",
        id: "all"
    },
    {
        value: "world",
        id: "world"
    },
    {
        value: "africa",
        id: "africa"
    },
    {
        value: "asia",
        id: "asia"
    },
    {
        value: "europe",
        id: "europe"
    },
    {
        value: "north_america",
        id: "north america"
    },
    {
        value: "south_america",
        id: "south america"
    },
    {
        value: "oceania",
        id: "oceania"
    }
];

export const subnationalCountriesName = ["KEN", "ETH", "IDN"];
