import { t } from "i18next";
import indexArrow from "assets/images/indexArrow.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ScrollToMap = () => (
    <a
        tw="bg-purple-600 sm:p-[1rem] p-[1.3rem] shadow text-white w-[4.75rem] h-[4.75rem] sm:w-[4.215rem] sm:h-[4.215rem] rounded-[50%] absolute uppercase tracking-[0.1em] text-[0.625rem] flex items-center flex-col gap-[0.5rem] bottom-[-2.375rem] left-[50%] translate-x-[-50%]"
        href="#map_internet_index"
    >
        {t("index")}
        <img src={indexArrow} alt="Index arrow" />
    </a>
);

export default ScrollToMap;
