export const povertyTableHead = (areSubregionsActive: boolean) => [
    {
        id: "name",
        value: areSubregionsActive
            ? "table_index.header_5"
            : "table_index.header_1"
    },
    {
        id: "totalPopulation",
        value: areSubregionsActive
            ? "table_index.header_6"
            : "table_index.header_2"
    },
    {
        id: "internetPoorPeople",
        value: "table_index.header_3"
    },
    {
        id: "overall",
        value: "table_index.header_4"
    }
];

export const pricingTableHead = (areSubregionsActive: boolean) => [
    {
        id: "name",
        value: areSubregionsActive
            ? "table_pricing.header_5"
            : "table_pricing.header_1"
    },
    {
        id: "internetPrice",
        value: "table_pricing.header_2"
    },
    {
        id: "bigByteIndex",
        value: "table_pricing.header_3"
    }
];
