import { useInternetPoverty } from "context/internetPoverty";
import "leaflet/dist/leaflet.css";
import Legend from "components/MapLegend/Legend";
import Tooltip from "components/Tooltip";
import { centerMap, regionStyle } from "utils/leaflet";
import Filters from "components/Filters";
import CountryDetails from "components/Country";
import { TooltipInfo } from "interfaces/TooltipInfo.interface";
import { useCallback, useEffect, useRef, useState } from "react";
import { GeoJSON, MapContainer, ZoomControl } from "react-leaflet";
import Loader from "components/Loader";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Map = () => {
    const {
        continent,
        country,
        map_section_data,
        setActiveFilters,
        areSubRegionsActive
    } = useInternetPoverty();
    const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo | null>(null);
    const [mapRef, setMapRef] = useState<any>(null);

    const geoJsonLayer = useRef<any>(null);

    useEffect(() => {
        if (geoJsonLayer.current) {
            geoJsonLayer.current.clearLayers().addData(map_section_data);
        }
    }, [map_section_data]);

    const highlightFeature = (layer: any) =>
        layer.target.feature.filteredNumPeople &&
        !layer.target.feature.disabled &&
        setTooltipInfo(layer);

    const zoomToCountry = useCallback(
        (clickedCountry: any, isForSubregion?: boolean) => {
            const selectedTarget: any = Object.values(
                geoJsonLayer.current._layers
            ).find(
                ({ feature }: any) =>
                    feature.id ===
                    (typeof clickedCountry === "string"
                        ? clickedCountry
                        : clickedCountry.target
                        ? clickedCountry.target.feature.id
                        : clickedCountry.id)
            );
            if (selectedTarget && !selectedTarget.feature.disabled) {
                setActiveFilters({
                    id:
                        isForSubregion || !isNaN(selectedTarget.feature.id)
                            ? selectedTarget.feature.properties.name
                            : selectedTarget.feature.id,
                    name:
                        isForSubregion || !isNaN(selectedTarget.feature.id)
                            ? "subregion"
                            : "country"
                });
                setTimeout(() => {
                    mapRef.target.invalidateSize();
                    mapRef.target.flyToBounds(selectedTarget.getBounds());
                }, 500);
            }
        },
        [mapRef?.target, setActiveFilters]
    );

    const mapInteractions = (_: any, layer: any) =>
        layer.on({
            mouseout: () => setTooltipInfo(null),
            mouseover: highlightFeature,
            click: zoomToCountry
        });

    const handleResetMapFilters = () => {
        const clearIconElement = document.querySelector(
            ".MuiAutocomplete-clearIndicator"
        ) as HTMLElement;
        if (clearIconElement) clearIconElement.click();
        setTimeout(() => {
            mapRef.target.invalidateSize();
            mapRef.target.flyTo(centerMap, 2);
        }, 500);
        setActiveFilters({
            id: "",
            name: "country"
        });
        setActiveFilters({
            id: "",
            name: "subregion"
        });
    };

    useEffect(() => {
        setTimeout(() => {
            if (
                continent !== "all" &&
                continent !== "world" &&
                country === ""
            ) {
                mapRef.target.invalidateSize();
                mapRef?.target.fitBounds(geoJsonLayer?.current.getBounds());
            } else if (country === "") mapRef?.target.flyTo(centerMap, 2);
        }, 500);
    }, [continent, country, mapRef?.target]);

    return (
        <>
            <Filters
                handleResetMapFilters={handleResetMapFilters}
                zoomToCountry={zoomToCountry}
            />
            <div
                css={[
                    tw`w-full relative`,
                    country && tw`grid grid-cols-[65% 1fr] justify-between`
                ]}
            >
                <div css={[tw`relative`, country && tw`pl-16`]}>
                    <MapContainer
                        whenReady={setMapRef as any}
                        center={centerMap}
                        zoom={1}
                        minZoom={1}
                        maxZoom={5}
                        zoomControl={false}
                        doubleClickZoom={false}
                        trackResize={false}
                        touchZoom={false}
                        scrollWheelZoom={false}
                    >
                        {map_section_data ? (
                            <GeoJSON
                                ref={geoJsonLayer}
                                data={map_section_data}
                                onEachFeature={mapInteractions}
                                style={(region) => regionStyle(region)}
                            />
                        ) : (
                            <Loader message="Loading map data" />
                        )}
                        {country === "" && (
                            <ZoomControl position="bottomright" />
                        )}
                    </MapContainer>
                    <Legend />
                    {((tooltipInfo && country === "") ||
                        (tooltipInfo && areSubRegionsActive)) && (
                        <Tooltip tooltipInfo={tooltipInfo as any} />
                    )}
                </div>
                {country && map_section_data && (
                    <CountryDetails
                        handleResetMapFilters={handleResetMapFilters}
                        zoomToCountry={zoomToCountry}
                    />
                )}
            </div>
        </>
    );
};

export default Map;
