import { theme } from "twin.macro";
import { LatLngTuple } from "leaflet";

export const centerMap: LatLngTuple = [22, 9.5];

export const regionStyle = (region: any) => {
    const fillColor = region.disabled
        ? theme`colors.gray.400`
        : region.filteredNumPeople <= 20
        ? theme`colors.blue.200`
        : region.filteredNumPeople <= 40
        ? theme`colors.blue.500`
        : region.filteredNumPeople <= 60
        ? theme`colors.blue.600`
        : region.filteredNumPeople <= 80
        ? theme`colors.blue.700`
        : region.filteredNumPeople <= 100
        ? theme`colors.blue.800`
        : theme`colors.gray.400`;

    return {
        fillColor,
        fillOpacity: 1,
        weight: 2.5,
        color: "white"
    };
};
