import React from "react";
import facebook from "assets/images/footer/socials/facebook.svg";
import instagram from "assets/images/footer/socials/instagram.svg";
import linkedIn from "assets/images/footer/socials/linkedin.svg";
import twitter from "assets/images/footer/socials/twitter.svg";
import website from "assets/images/footer/socials/website.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Socials = () => (
    <div tw="flex flex-col gap-[1rem]">
        <h3 tw="font-bold tracking-[0.04em] text-sm">CONTACT US</h3>
        <a href="mailto:hello@worlddata.io">hello@worlddata.io</a>
        <div tw="flex justify-between gap-[1rem]">
            <a
                tw="w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-[50%] bg-white"
                href="https://worlddata.io/"
            >
                <img src={website} alt="Website icon" />
            </a>
            <a
                tw="w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-[50%] bg-white"
                href="https://www.linkedin.com/company/world-data-lab"
            >
                <img src={linkedIn} alt="LinkedIn icon" />
            </a>
            <a
                tw="w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-[50%] bg-white"
                href="https://twitter.com/worlddatalab"
            >
                <img src={twitter} alt="Twitter icon" />
            </a>
            <a
                tw="w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-[50%] bg-white"
                href="https://www.facebook.com/worlddatalab/"
            >
                <img src={facebook} alt="Facebook icon" />
            </a>
            <a
                tw="w-[2.5rem] h-[2.5rem] flex items-center justify-center rounded-[50%] bg-white"
                href="https://instagram.com/worlddatalab"
            >
                <img src={instagram} alt="Instagram icon" />
            </a>
        </div>
    </div>
);
export default Socials;
