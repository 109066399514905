import { Fragment } from "react";
import { useInternetPoverty } from "context/internetPoverty";
import { t } from "i18next";
import { TableItem } from "interfaces/TableItem.interface";
import { povertyTableHead, pricingTableHead } from "utils/Tables";
import { handleFinalData, handleFinalPercentageData } from "utils/Country";
import Filters from "components/Filters";
import USARow from "components/Tables/USARow";
import { formatNumber } from "utils/Data";
import info from "assets/images/info.svg";
import Loader from "components/Loader";
import sortIcon from "assets/images/sortIcon.svg";
/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { subnationalCountriesName } from "utils/Items";

const Data = () => {
    const {
        age,
        areSubRegionsActive,
        countryData,
        country,
        gender,
        map_section_data,
        table_data,
        ranking,
        setTableData
    } = useInternetPoverty();

    const data =
        areSubRegionsActive && subnationalCountriesName.includes(country)
            ? table_data.data
            : countryData
            ? [countryData]
            : table_data.data;

    const handleTableHead = () => {
        const tableHeads =
            ranking === "poverty"
                ? povertyTableHead(
                      areSubRegionsActive &&
                          subnationalCountriesName.includes(country)
                  )
                : pricingTableHead(
                      areSubRegionsActive &&
                          subnationalCountriesName.includes(country)
                  );
        return tableHeads.map(({ id, value }) => (
            <Fragment key={value}>
                <button
                    tw="flex items-center gap-[0.5rem] w-[23%] justify-center relative text-regular lg:text-sm"
                    onClick={() => {
                        setTableData({
                            id,
                            name: "category"
                        });
                        setTableData({
                            name: "isDescDirection",
                            id:
                                id === table_data.category
                                    ? !table_data.isDescDirection
                                    : false
                        });
                    }}
                    name={id}
                >
                    {t(value)} <img alt="Sort" src={sortIcon} />
                    {ranking === "internet_pricing" &&
                        id === "internetPrice" && (
                            <img id="info" src={info} alt="Info" />
                        )}
                    {ranking === "internet_pricing" &&
                        id === "internetPrice" && (
                            <div tw="hidden z-[1] absolute p-[0.5rem] text-white text-xs text-left bg-purple-900 rounded-[0.5rem] w-[19rem] top-[1.5rem]">
                                {t("price_info")}
                                <br />
                                {t("price_info_2")}
                                <br />
                                {t("price_info_3")}
                            </div>
                        )}
                </button>
            </Fragment>
        ));
    };

    return (
        <>
            <Filters
                handleResetMapFilters={() => null}
                zoomToCountry={() => null}
            />
            <article
                tw="sm:px-[1rem] px-[4rem] md:overflow-scroll relative h-[32.5rem]"
                className="scrollable"
            >
                {!map_section_data ? (
                    <Loader message={"Loading data"} />
                ) : (
                    <div tw="md:w-[200%]">
                        <div tw="bg-purple-600 text-white font-medium text-sm flex justify-between p-[1.125rem] rounded-[0.5rem 0.5rem 0 0]">
                            <span tw="w-[8%]"></span>
                            {handleTableHead()}
                        </div>
                        {ranking === "internet_pricing" && <USARow />}
                        <div
                            css={[
                                tw`text-black text-[1.125rem] max-h-[29rem] overflow-scroll`,
                                ranking === "internet_pricing" &&
                                    tw`max-h-[25rem]`
                            ]}
                        >
                            {data.map(
                                (item: TableItem, i: number) =>
                                    item.internetPrice && (
                                        <div
                                            key={item.name}
                                            tw="lg:text-sm flex justify-between items-center text-center p-[1.125rem] odd-of-type:bg-purple-100"
                                        >
                                            <span tw="w-[8%] font-semiBold">
                                                #{i + 1}
                                            </span>
                                            <span tw="w-[23%]">
                                                {item.name}
                                            </span>
                                            {ranking === "poverty" && (
                                                <>
                                                    <span tw="w-[23%]">
                                                        {handleFinalData(
                                                            age,
                                                            item,
                                                            gender,
                                                            "totalPopulation"
                                                        ).toLocaleString()}
                                                    </span>
                                                    <span tw="w-[23%]">
                                                        {handleFinalData(
                                                            age,
                                                            item,
                                                            gender,
                                                            "internetPoorPeople"
                                                        ).toLocaleString()}
                                                    </span>
                                                    <span tw="w-[23%] rounded-[1rem] bg-purple-500 max-h-[2.18rem] text-white font-semiBold py-[0.25rem]">
                                                        {formatNumber(
                                                            handleFinalPercentageData(
                                                                age,
                                                                item,
                                                                gender,
                                                                "overall"
                                                            ),
                                                            ranking
                                                        )}
                                                        %
                                                    </span>
                                                </>
                                            )}
                                            {ranking === "internet_pricing" && (
                                                <>
                                                    {item.internetPrice && (
                                                        <span tw="w-[23%]">
                                                            ${" "}
                                                            {`${formatNumber(
                                                                item.internetPrice, ranking
                                                            )}/${t("month")}`}
                                                        </span>
                                                    )}
                                                    {item.bigByteIndex && (
                                                        <span
                                                            css={[
                                                                tw`w-[23%] rounded-[1rem] max-h-[2.18rem] text-white font-semiBold py-[0.25rem]`,
                                                                item.bigByteIndex >
                                                                100
                                                                    ? tw`bg-red`
                                                                    : item.bigByteIndex ===
                                                                      100
                                                                    ? tw`bg-purple-500`
                                                                    : tw`bg-green`
                                                            ]}
                                                        >
                                                            {formatNumber(
                                                                item.bigByteIndex, ranking
                                                            )}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )
                            )}
                        </div>
                    </div>
                )}
            </article>
        </>
    );
};

export default Data;
