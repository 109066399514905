import React from "react";
import { useInternetPoverty } from "context/internetPoverty";
import Data from "components/Tables/Data";
import { t } from "i18next";
import Map from "components/Map";
import YearSlider from "components/YearSlider";
import byteIcon from "assets/images/byte.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const MapSection = () => {
    const { type_of_visualization } = useInternetPoverty();

    return (
        <section tw="pt-[4rem] relative" id="map_internet_index">
            <div tw="max-w-[90rem] mx-auto">
                <div tw="grid grid-cols-[minmax(12.5rem, 40rem) minmax(12.5rem, 40rem)] md:grid-cols-none justify-between gap-[2rem] text-black sm:px-[1rem] px-[4rem] md:gap-[2rem]">
                    <div tw="flex flex-col gap-[1rem] sm:gap-[0.5rem]">
                        <h2 tw="text-sm tracking-[0.2em] sm:text-xs">
                            {t("map_section_h2")}
                        </h2>
                        <h3 tw="font-bold text-xxl">{t("map_section_h3")}</h3>
                        <p tw="font-semiBold sm:text-sm text-lg leading-7">
                            {t("map_section_p1")}
                        </p>
                    </div>
                    <div tw="rounded-[1rem] bg-gray-200 p-[1rem] md:max-w-none">
                        <div tw="flex items-center gap-[2rem] pb-[1rem]">
                            <img src={byteIcon} alt="Explore internet index" />
                            <h3 tw="font-semiBold sm:text-sm text-lg leading-7">
                                {t("map_section_p3")}
                            </h3>
                        </div>
                        <p>{t("map_section_p4")}</p>
                    </div>
                </div>
                {type_of_visualization === "map" ? <Map /> : <Data />}
                <YearSlider />
            </div>
        </section>
    );
};

export default MapSection;
