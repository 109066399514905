import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Long: bigint;
};

export type AgeGroupGeneric = {
  __typename?: 'AgeGroupGeneric';
  from: Scalars['Int'];
  to?: Maybe<Scalars['Int']>;
};

export type ByAgeGenderAndCountryLine = {
  __typename?: 'ByAgeGenderAndCountryLine';
  bigByteIndex: Scalars['Float'];
  country: CountryI;
  internetPrice: Scalars['Float'];
  value: ByGenderLine;
  values: Array<ByAgeGenderLine>;
};

export type ByAgeGenderLine = {
  __typename?: 'ByAgeGenderLine';
  ageGroup: AgeGroupGeneric;
  value: ByGenderLine;
};

export type ByGenderLine = {
  __typename?: 'ByGenderLine';
  females: PopulationAndNumInternetPoor;
  males: PopulationAndNumInternetPoor;
  overall: PopulationAndNumInternetPoor;
};

export type CountryI = {
  __typename?: 'CountryI';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type InternetPoverty = {
  __typename?: 'InternetPoverty';
  value: ByGenderLine;
  values: Array<ByAgeGenderAndCountryLine>;
};

export type PopulationAndNumInternetPoor = {
  __typename?: 'PopulationAndNumInternetPoor';
  totalPopulation: Scalars['Long'];
  value: Scalars['Long'];
};

export type Query = {
  __typename?: 'Query';
  internetPoverty?: Maybe<InternetPoverty>;
};


export type QueryInternetPovertyArgs = {
  countryIds: Array<Scalars['String']>;
  year: Scalars['Int'];
};

export type HeroSectionQueryVariables = Exact<{
  year?: Scalars['Int'];
}>;


export type HeroSectionQuery = { __typename?: 'Query', heroSection?: { __typename?: 'InternetPoverty', value: { __typename?: 'ByGenderLine', overall: { __typename?: 'PopulationAndNumInternetPoor', value: bigint }, males: { __typename?: 'PopulationAndNumInternetPoor', value: bigint }, females: { __typename?: 'PopulationAndNumInternetPoor', value: bigint } } } | null };

export type MapSectionQueryVariables = Exact<{
  year?: Scalars['Int'];
}>;


export type MapSectionQuery = { __typename?: 'Query', mapSection?: { __typename?: 'InternetPoverty', values: Array<{ __typename?: 'ByAgeGenderAndCountryLine', internetPrice: number, bigByteIndex: number, country: { __typename?: 'CountryI', id: string, name: string }, population: { __typename?: 'ByGenderLine', overall: { __typename?: 'PopulationAndNumInternetPoor', totalPopulation: bigint, internetPoorPeople: bigint }, males: { __typename?: 'PopulationAndNumInternetPoor', totalPopulation: bigint, internetPoorPeople: bigint }, females: { __typename?: 'PopulationAndNumInternetPoor', totalPopulation: bigint, internetPoorPeople: bigint } }, perAgeGroup: Array<{ __typename?: 'ByAgeGenderLine', ageGroup: { __typename?: 'AgeGroupGeneric', from: number, to?: number | null }, value: { __typename?: 'ByGenderLine', overall: { __typename?: 'PopulationAndNumInternetPoor', totalPopulation: bigint, internetPoorPeople: bigint }, males: { __typename?: 'PopulationAndNumInternetPoor', totalPopulation: bigint, internetPoorPeople: bigint }, females: { __typename?: 'PopulationAndNumInternetPoor', totalPopulation: bigint, internetPoorPeople: bigint } } }> }> } | null };


export const HeroSectionDocument = gql`
    query heroSection($year: Int! = 2024) {
  heroSection: internetPoverty(year: $year, countryIds: []) {
    value {
      overall {
        value
      }
      males {
        value
      }
      females {
        value
      }
    }
  }
}
    `;

/**
 * __useHeroSectionQuery__
 *
 * To run a query within a React component, call `useHeroSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeroSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeroSectionQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useHeroSectionQuery(baseOptions?: Apollo.QueryHookOptions<HeroSectionQuery, HeroSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeroSectionQuery, HeroSectionQueryVariables>(HeroSectionDocument, options);
      }
export function useHeroSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeroSectionQuery, HeroSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeroSectionQuery, HeroSectionQueryVariables>(HeroSectionDocument, options);
        }
export type HeroSectionQueryHookResult = ReturnType<typeof useHeroSectionQuery>;
export type HeroSectionLazyQueryHookResult = ReturnType<typeof useHeroSectionLazyQuery>;
export type HeroSectionQueryResult = Apollo.QueryResult<HeroSectionQuery, HeroSectionQueryVariables>;
export function refetchHeroSectionQuery(variables?: HeroSectionQueryVariables) {
      return { query: HeroSectionDocument, variables: variables }
    }
export const MapSectionDocument = gql`
    query mapSection($year: Int! = 2024) {
  mapSection: internetPoverty(year: $year, countryIds: []) {
    values {
      country {
        id
        name
      }
      internetPrice
      bigByteIndex
      population: value {
        overall {
          totalPopulation
          internetPoorPeople: value
        }
        males {
          totalPopulation
          internetPoorPeople: value
        }
        females {
          totalPopulation
          internetPoorPeople: value
        }
      }
      perAgeGroup: values {
        ageGroup {
          from
          to
        }
        value {
          overall {
            totalPopulation
            internetPoorPeople: value
          }
          males {
            totalPopulation
            internetPoorPeople: value
          }
          females {
            totalPopulation
            internetPoorPeople: value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMapSectionQuery__
 *
 * To run a query within a React component, call `useMapSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapSectionQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useMapSectionQuery(baseOptions?: Apollo.QueryHookOptions<MapSectionQuery, MapSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MapSectionQuery, MapSectionQueryVariables>(MapSectionDocument, options);
      }
export function useMapSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MapSectionQuery, MapSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MapSectionQuery, MapSectionQueryVariables>(MapSectionDocument, options);
        }
export type MapSectionQueryHookResult = ReturnType<typeof useMapSectionQuery>;
export type MapSectionLazyQueryHookResult = ReturnType<typeof useMapSectionLazyQuery>;
export type MapSectionQueryResult = Apollo.QueryResult<MapSectionQuery, MapSectionQueryVariables>;
export function refetchMapSectionQuery(variables?: MapSectionQueryVariables) {
      return { query: MapSectionDocument, variables: variables }
    }