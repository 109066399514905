export const handleFilteredAgeData = (age: string, countryData: any) =>
    countryData.perAgeGroup.find((item: any) => {
        if (item.ageGroup.to === null) return "65" === age;
        return (
            `${item.ageGroup.from.toString()}-${item.ageGroup.to.toString()}` ===
            age
        );
    });

export const handleFinalData = (
    age: string,
    countryData: any,
    gender: string,
    populationOrPoverty: string
) =>
    age === "all"
        ? countryData.population[gender][populationOrPoverty]
        : handleFilteredAgeData(age, countryData).value[gender][
              populationOrPoverty
          ];

export const handleFinalPercentageData = (
    age: string,
    countryData: any,
    activeGender: string,
    sectionGender?: string
) =>
    age === "all"
        ? (countryData.population[activeGender].internetPoorPeople * 100) /
          countryData.population[sectionGender ? sectionGender : activeGender]
              .totalPopulation
        : (handleFilteredAgeData(age, countryData).value[activeGender]
              .internetPoorPeople *
              100) /
          handleFilteredAgeData(age, countryData).value[
              sectionGender ? sectionGender : activeGender
          ].totalPopulation;
