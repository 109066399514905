import { useInternetPoverty } from "context/internetPoverty";
import { t } from "i18next";
import { formatNumber } from "utils/Data";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const USARow = () => {
    const { ranking, baseByteIndexRowRow } = useInternetPoverty();

    return (
        <div
            key={baseByteIndexRowRow.name}
            tw="font-medium flex justify-between items-center text-center p-[1.125rem] odd-of-type:bg-purple-100"
        >
            <span tw="w-[8%] font-medium"></span>
            <span tw="w-[23%]">{baseByteIndexRowRow.name} - base</span>
            {ranking === "internet_pricing" && (
                <>
                    {baseByteIndexRowRow.internetPrice && (
                        <span tw="w-[23%]">
                            ${" "}
                            {`${formatNumber(
                                baseByteIndexRowRow.internetPrice, ranking
                            )}/${t("month")}`}
                        </span>
                    )}
                    {baseByteIndexRowRow.bigByteIndex && (
                        <span tw="w-[23%] rounded-[1rem] bg-purple-500 max-h-[2.18rem] text-white font-semiBold py-[0.25rem]">
                            {formatNumber(baseByteIndexRowRow.bigByteIndex, ranking)}
                        </span>
                    )}
                </>
            )}
        </div>
    );
};

export default USARow;
