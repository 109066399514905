import React from "react";
import "./App.css";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "i18n/en.json";
import InternetClock from "views/InternetClock";
import TagManager from "react-gtm-module";
import { InternetClockContextProvider } from "context/internetPoverty";
import ErrorBoundary from "components/ErrorBoundary";

const tagManagerArgs = {
    gtmId: "G-FZXR921QV5",
    dataLayerName: "pageView"
};

TagManager.initialize(tagManagerArgs);

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(detector) // detect user language
    .init({
        fallbackLng: "en",
        interpolation: {
            escapeValue: true
        },
        resources: {
            en: {
                translation: en
            }
        }
    });

const App = () => {
    TagManager.dataLayer(tagManagerArgs);

    return (
        <ErrorBoundary>
            <InternetClockContextProvider>
                <InternetClock />
            </InternetClockContextProvider>
        </ErrorBoundary>
    );
};

export default App;
