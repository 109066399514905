import geoJson from "data/countries_geojson.json";
import continents from "data/continents.json";
import flags from "data/countries_flags.json";
import { ApolloQueryProps, ApolloVariables } from "interfaces/apollo.interface";
import { Country } from "interfaces/country.interface";
import { createContext, useContext, useReducer } from "react";
import { globalStateReducer } from "reducers/globalState";
import kenyaSubRegions from "data/subRegions/kenya.json";
import indonesiaSubRegions from "data/subRegions/indonesia.json";
import ethiopiaSubRegions from "data/subRegions/ethiopia.json";
import {
    useHeroSectionQuery,
    useMapSectionQuery
} from "utils/__generated__/graphql";
import {
    ChildrenProps,
    GlobalState,
    ActiveFiltersDispatchProps
} from "interfaces/internet-scarcity-context.interface";
import {
    handleFilteredAgeData,
    handleFinalPercentageData
} from "utils/Country";
import { handleReduceDataIds } from "utils/Data";
import { subnationalCountriesName } from "utils/Items";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";

let mergedMapData: any;
let sortedData: any;
let mergedSubRegionData: any[] = [
    ethiopiaSubRegions,
    kenyaSubRegions,
    indonesiaSubRegions
];
let updatedCountry: any;
let updatedSubRegion: any;
let sortedTableData: any;
let baseByteIndexRow: any;

const initialState: GlobalState = {
    areSubRegionsActive: false,
    age: "all",
    continent: "all",
    country: "",
    countryData: null,
    countrySubRegionData: null,
    gender: "overall",
    hero_section_data: {},
    map_section_data: {},
    mergedSubRegionData: null,
    ranking: "poverty",
    table_data: {
        category: "name",
        isDescDirection: false,
        data: []
    },
    type_of_visualization: "map",
    year: 2024,
    setActiveFilters: null,
    setTableData: null,
    subregion: "",
    baseByteIndexRowRow: {}
};

const InternetClockContext = createContext(initialState);

export const InternetClockContextProvider = ({ children }: ChildrenProps) => {
    const [state, dispatch] = useReducer(globalStateReducer, initialState);

    const variables: ApolloVariables = {
        year: state.year
    };

    const {
        loading: isHeroSectionDataLoading,
        data: heroSectionData
    }: ApolloQueryProps = useHeroSectionQuery({
        variables
    });

    const {
        loading: isMapSectionDataLoading,
        data: mapSectionData
    }: ApolloQueryProps = useMapSectionQuery({
        variables
    });

    const { data: subRegionsData } = useFetch(
        `${urls.API_SUBREGION_URL}/countries/${state.country}/regions?year=${state.year}`,
        !subnationalCountriesName.includes(state.country)
    );

    if (!isMapSectionDataLoading) {
        const geoJsonIds = handleReduceDataIds(
            geoJson[0].features,
            (item) => item.id
        );
        const geoJsonContinentIds = handleReduceDataIds(
            continents[0].data,
            (item) => item.id
        );
        const flagsIds = handleReduceDataIds(
            flags[0].data,
            (item) => item.iso3c
        );
        const mapSectionDataIds = handleReduceDataIds(
            mapSectionData.mapSection.values,
            (item) => item.country.id
        );
        const ids = [...Object.keys(geoJsonIds)];
        const newSetIds: any = new Set(ids);
        mergedMapData = [...newSetIds].map((id) => ({
            filteredNumPeople: mapSectionDataIds[id]
                ? handleFinalPercentageData(
                      state.age,
                      { ...mapSectionDataIds[id] },
                      state.gender
                  )
                : undefined,
            ...geoJsonIds[id],
            ...geoJsonContinentIds[id],
            ...flagsIds[id],
            ...mapSectionDataIds[id]
        }));
        baseByteIndexRow =
            state.areSubRegionsActive &&
            state.country &&
            subnationalCountriesName.includes(state.country)
                ? mergedMapData.find(
                      (country: any) => country?.id === state.country
                  )
                : mergedMapData.find((country: any) => country?.id === "USA");
        if (state.continent !== "all" && state.continent !== "world")
            mergedMapData = mergedMapData.filter(
                ({ continent }: any) => continent === state.continent
            );

        updatedCountry = mergedMapData.find(
            ({ id }: Country) => id === state.country
        );

        mergedMapData = mergedMapData.map((item: any) => {
            const findCountry = mergedSubRegionData.find(
                (subRegions: any) => subRegions.id === item.id
            );

            return {
                ...item,
                subRegions: { ...findCountry },
                disabled:
                    state.areSubRegionsActive && !findCountry
                        ? true
                        : !state.areSubRegionsActive &&
                          item.filteredNumPeople === undefined
                        ? true
                        : false
            };
        });

        if (
            state.areSubRegionsActive &&
            state.country &&
            subnationalCountriesName.includes(state.country)
        ) {
            mergedMapData = [
                mergedMapData.find((item: any) => item.id === state.country)
                    .subRegions
            ];
        }

        if (
            state.areSubRegionsActive &&
            state.country &&
            subnationalCountriesName.includes(state.country) &&
            subRegionsData
        ) {
            mergedMapData = mergedMapData[0].features
                .map((subregion: any) => {
                    const matchedSubregion = subRegionsData.find(
                        ({ regionName }: any) =>
                            regionName === subregion.properties.name
                    );

                    return {
                        ...matchedSubregion,
                        ...subregion,
                        disabled: matchedSubregion ? false : true,
                        filteredNumPeople: matchedSubregion
                            ? handleFinalPercentageData(
                                  state.age,
                                  { ...matchedSubregion },
                                  state.gender
                              )
                            : undefined
                    };
                })
                .sort((countryA: any, countryB: any) =>
                    countryA.properties.name.localeCompare(
                        countryB.properties.name
                    )
                );
        }

        if (state.subregion && subRegionsData)
            updatedSubRegion = {
                ...subRegionsData.find(
                    ({ regionName }: { regionName: string }) =>
                        regionName === state.subregion
                ),
                country: { name: updatedCountry?.name },
                file_url: updatedCountry?.file_url
            };

        if (state.ranking === "internet_pricing")
            mergedMapData = mergedMapData.filter(
                (country: any) => country.id !== "USA"
            );

        if (state.type_of_visualization !== "map") {
            const sortByCategory = state.table_data.category;

            const handleSortByCategory = (item: any) => {
                if (state.age !== "all") {
                    return sortByCategory === "name"
                        ? item.name
                        : sortByCategory === "totalPopulation"
                        ? handleFilteredAgeData(state.age, item).value[
                              state.gender
                          ].totalPopulation
                        : sortByCategory === "internetPoorPeople"
                        ? handleFilteredAgeData(state.age, item).value[
                              state.gender
                          ].internetPoorPeople
                        : sortByCategory === "bigBiteIndex"
                        ? handleFilteredAgeData(state.age, item).value[
                              state.gender
                          ].bigBiteIndex
                        : sortByCategory === "internetPrice"
                        ? handleFilteredAgeData(state.age, item).value[
                              state.gender
                          ].internetPrice
                        : handleFilteredAgeData(state.age, item).value[
                              state.gender
                          ].totalPopulation /
                          handleFilteredAgeData(state.age, item).value[
                              state.gender
                          ].internetPoorPeople;
                } else {
                    return sortByCategory === "name"
                        ? item.name
                        : sortByCategory === "totalPopulation"
                        ? item.population[state.gender]?.totalPopulation
                        : sortByCategory === "internetPoorPeople"
                        ? item.population[state.gender]?.internetPoorPeople
                        : sortByCategory === "bigByteIndex"
                        ? item.bigByteIndex
                        : sortByCategory === "internetPrice"
                        ? item.internetPrice
                        : item.population[state.gender]?.totalPopulation /
                          item.population[state.gender]?.internetPoorPeople;
                }
            };

            if (
                state.areSubRegionsActive &&
                state.country &&
                subRegionsData &&
                subnationalCountriesName.includes(state.country)
            )
                sortedData = mergedMapData.map((subregion: any) => {
                    const matchedSubregion = subRegionsData.find(
                        ({ regionName }: any) =>
                            regionName === subregion.properties.name
                    );

                    return {
                        ...matchedSubregion,
                        ...subregion,
                        name: subregion.properties.name
                    };
                });
            else if (state.areSubRegionsActive)
                sortedData = [...mergedMapData].filter((item: any) =>
                    subnationalCountriesName.includes(item.id)
                );
            else
                sortedData = [...mergedMapData].filter(
                    (item: any) => typeof item.filteredNumPeople === "number"
                );

            sortedData = sortedData.filter(
                (item: any) => item.filteredNumPeople !== undefined
            );

            sortedTableData = {
                ...state.table_data,
                data: sortedData.sort((itemA: any, itemB: any) =>
                    state.table_data.isDescDirection
                        ? sortByCategory === "name"
                            ? handleSortByCategory(itemB).localeCompare(
                                  handleSortByCategory(itemA)
                              )
                            : handleSortByCategory(itemB) -
                              handleSortByCategory(itemA)
                        : sortByCategory === "name"
                        ? handleSortByCategory(itemA).localeCompare(
                              handleSortByCategory(itemB)
                          )
                        : handleSortByCategory(itemA) -
                          handleSortByCategory(itemB)
                )
            };
        }
    }

    const setActiveFilters = ({ id, name }: ActiveFiltersDispatchProps) =>
        dispatch({
            payload: { id, name },
            type: "SELECT_FILTERS"
        });

    const setTableData = ({ id, name }: ActiveFiltersDispatchProps) =>
        dispatch({
            payload: { id, name },
            type: "SORT_TABLE_DATA"
        });

    const value = {
        areSubRegionsActive: state.areSubRegionsActive,
        age: state.age,
        continent: state.continent,
        country: state.country,
        countryData: updatedCountry,
        countrySubRegionData: updatedSubRegion,
        gender: state.gender,
        hero_section_data:
            !isHeroSectionDataLoading && heroSectionData.heroSection.value,
        map_section_data: !isMapSectionDataLoading && mergedMapData,
        mergedSubRegionData: mergedSubRegionData,
        ranking: state.ranking,
        table_data: sortedTableData,
        type_of_visualization: state.type_of_visualization,
        year: state.year,
        setActiveFilters,
        setTableData,
        subregion: state.subregion,
        baseByteIndexRowRow: baseByteIndexRow
    };

    return (
        <InternetClockContext.Provider value={value}>
            {children}
        </InternetClockContext.Provider>
    );
};

export const useInternetPoverty = () => {
    const context = useContext(InternetClockContext);
    if (context === undefined)
        throw new Error(
            "useInternetPoverty needs to be within the InternetClockContext"
        );

    return context;
};
