import { Actions } from "interfaces/global-state.interface";
import { GlobalState } from "interfaces/internet-scarcity-context.interface";

export const globalStateReducer = (
    state: GlobalState,
    { type, payload }: Actions
) => {
    switch (type) {
        case "SELECT_FILTERS":
            return {
                ...state,
                [payload.name]:
                    typeof payload.id === "boolean" ? !payload.id : payload.id
            };
        case "SORT_TABLE_DATA":
            return {
                ...state,
                table_data: {
                    ...state.table_data,
                    [payload.name]: payload.id
                }
            };
        default:
            throw new Error();
    }
};
