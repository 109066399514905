import Header from "components/Header";
import HeroSection from "components/Main/HeroSection";
import MapSection from "components/Main/MapSection";
import RankingsSection from "components/Main/RankingsSection";
import Footer from "components/Footer";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const InternetPoverty = () => (
    <>
        <Header />
        <main>
            <HeroSection />
            <MapSection />
            <RankingsSection />
        </main>
        <Footer />
    </>
);

export default InternetPoverty;
