import { t } from "i18next";
import { useInternetPoverty } from "context/internetPoverty";
import { TooltipInfo } from "interfaces/TooltipInfo.interface";
import { handleFinalData, handleFinalPercentageData } from "utils/Country";
import { formatNumber } from "utils/Data";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Tooltip = ({ tooltipInfo }: TooltipInfo) => {
    const { age, gender } = useInternetPoverty();

    return (
        <div
            style={{
                left: tooltipInfo?.originalEvent?.clientX,
                top: tooltipInfo?.originalEvent?.clientY
            }}
            css={[
                tw`w-[23rem] border h-full max-h-[16.16rem] text-gray-700 border-gray-600 rounded-[0.5rem] p-[1.25rem] shadow md:hidden flex flex-col items-start gap-[0.5rem] fixed bottom-0 right-[4rem] z-[1040] bg-white`,
                tooltipInfo.target.feature.regionName &&
                    tw`max-h-[12rem] h-auto`
            ]}
        >
            <div tw="flex items-center gap-[0.5rem] text-purple-600 font-bold text-sm">
                {tooltipInfo?.target.feature.file_url && (
                    <img
                        width={24}
                        src={tooltipInfo?.target.feature.file_url}
                        alt="Flag"
                    />
                )}
                <h3>{tooltipInfo?.target.feature.properties.name}</h3>
            </div>
            <div tw="flex items-center gap-[0.5rem] text-sm uppercase font-bold leading-[1.75rem] tracking-[0.055rem]">
                <h4>{t("map_tool_population")}</h4>
                <span tw="font-regular">
                    {handleFinalData(
                        age,
                        tooltipInfo?.target.feature,
                        "overall",
                        "totalPopulation"
                    ).toLocaleString()}
                </span>
            </div>
            <div tw="flex flex-col gap-[0.5rem]">
                <h4 tw="text-sm uppercase font-bold leading-[1.75rem] tracking-[0.055rem]">
                    {t("map_tooltip_poor_population_internet")}
                </h4>
                <p>
                    {handleFinalData(
                        age,
                        tooltipInfo?.target.feature,
                        "overall",
                        "internetPoorPeople"
                    ).toLocaleString()}{" "}
                    |{" "}
                    {formatNumber(
                        parseFloat(
                            handleFinalPercentageData(
                                age,
                                tooltipInfo?.target.feature,
                                gender,
                                gender
                            )
                                .toFixed(1)
                                .toLocaleString()
                        )
                    )}
                    %
                    <span tw="text-sm">
                        {" "}
                        {t("country_selected_total_population")}
                    </span>
                </p>
            </div>
            {!tooltipInfo.target.feature.regionName && (
                <div tw="flex items-center gap-[0.5rem]">
                    <h4 tw="text-sm uppercase font-bold leading-[1.75rem] tracking-[0.055rem]">
                        {t("map_tooltip_internet_price")}
                    </h4>
                    <p tw="text-gray-700">
                        ${" "}
                        {tooltipInfo?.target.feature.internetPrice
                            .toFixed(1)
                            .toLocaleString()}
                    </p>
                </div>
            )}
            {!tooltipInfo.target.feature.regionName && (
                <div tw="flex gap-[0.5rem]">
                    <h4 tw="uppercase font-bold tracking-[0.055rem]">
                        {t("map_tooltip_big_byte_index")}
                    </h4>{" "}
                    <p tw="text-gray-700">
                        {parseFloat(
                            tooltipInfo?.target.feature.bigByteIndex
                                .toFixed(1)
                                .toLocaleString()
                        )}
                    </p>
                </div>
            )}
            <button tw="text-purple-700 font-medium tracking-[0.055rem] text-sm">
                {t("map_tooltip_detail_button")}
            </button>
        </div>
    );
};

export default Tooltip;
