import React from "react";
import internetSocietyLogo from "assets/images/footer/supporter/InternetSocietyFoundationLogo.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Supporter = () => (
    <div tw="flex flex-col lg:w-full gap-[2rem] text-sm">
        <p>Supported by</p>
        <img src={internetSocietyLogo} alt="Internet Society Foundation" />
    </div>
);

export default Supporter;
