import { MarksPair } from "interfaces/Slider.interface";

export const slidermarks: MarksPair[] = [
    {
        label: "2015",
        value: 2015
    },
    {
        label: "2016",
        value: 2016
    },
    {
        label: "2017",
        value: 2017
    },
    {
        label: "2018",
        value: 2018
    },
    {
        label: "2019",
        value: 2019
    },
    {
        label: "2020",
        value: 2020
    },
    {
        label: "2021",
        value: 2021
    },
    {
        label: "2022",
        value: 2022
    },
    {
        label: "2023",
        value: 2023
    },
    {
        label: "2024",
        value: 2024
    }
];
