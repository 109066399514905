import logo from "assets/images/logo.svg";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Header = () => (
    <header tw="bg-white text-black shadow p-[2rem 4rem] sm:p-[1rem] z-[1001] relative">
        <div tw="max-w-[90rem] mx-auto flex items-center  w-full gap-[1rem]">
            <img src={logo} alt="Internet Poverty Index" />
            <div tw="text-xs">
                <h1 tw="font-semiBold tracking-[0.1rem] uppercase">
                    {t("title")}
                </h1>
                <span tw="tracking-[0.2rem]">{t("sub_title")}</span>
            </div>
        </div>
    </header>
);

export default Header;
