import { BaseSyntheticEvent, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import search from "assets/images/search.svg";
import arrow from "assets/images/arrow.svg";
import filters from "assets/images/filtersIcon.svg";
import reset from "assets/images/reset.svg";
import useToggle from "hooks/useToggle";
import MobileYearDropDown from "components/YearSlider/MobileYearDropdown";
import MobileFiltersModal from "components/MobileFiltersModal";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { t } from "i18next";
import { useInternetPoverty } from "context/internetPoverty";
import {
    genders,
    types,
    ages,
    continents,
    rankingTypes,
    subnationalCountriesName
} from "utils/Items";
import { Types } from "interfaces/Types.interface";
import { Country } from "interfaces/country.interface";
import { styled } from "@mui/material/styles";
import ClearIcon from "components/Filters/ClearIcon";
import {
    ToggleSwitch,
    ToggleSwitchLabel
} from "components/Map/ToggleSwitch/ToggleSwitch";
import { isInternetPricingActive } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const CustomSelect = styled(Select)<any>(() => ({
    ".MuiInputBase-input": {
        color: theme`colors.purple.600`,
        padding: "0 1rem",
        height: "calc(2.5rem - 3px) !important",
        border: `solid 1.5px ${theme`colors.purple.600`}`,
        borderRadius: "0.5rem",
        display: "flex",
        alignItems: "center"
    },
    ".MuiSelect-icon": {
        color: theme`colors.purple.600`
    }
}));

const Filters = ({ handleResetMapFilters, zoomToCountry }: any) => {
    const {
        age,
        continent,
        country,
        countryData,
        gender,
        map_section_data,
        ranking,
        type_of_visualization,
        setActiveFilters,
        areSubRegionsActive
    } = useInternetPoverty();
    const { handleToggleState, toggleState } = useToggle();

    const handleSelectOptions = (types: Types[], disabled: boolean) =>
        types.map(({ id, value }: Types) => (
            <MenuItem
                key={id}
                value={id}
                css={[tw`text-sm`, value === "select_continent" && tw`hidden`]}
                disabled={disabled}
            >
                {t(value)}
            </MenuItem>
        ));

    const TextFieldStyle = {
        "& .MuiAutocomplete-clearIndicator": {
            marginRight: "2rem !important",
            visibility: "visible !important"
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "unset !important"
        },
        "& .MuiAutocomplete-input": {
            background: country ? "#CCE1FF" : "",
            borderRadius: "0.5rem",
            color: theme`colors.purple.600`,
            fontWeight: 400
        },
        "& .MuiAutocomplete-popupIndicator svg": {
            display: "none"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0
        },
        "& .MuiTextField-root": {
            background: `url(${arrow}) no-repeat 100%`,
            backgroundPosition: "right 1rem center",
            border: "1px solid #353F87",
            borderRadius: "0.5rem"
        },
        "& .MuiOutlinedInput-root": {
            paddingLeft: "2rem",
            paddingRight: "0.25rem",
            paddingTop: "0.1rem",
            paddingBottom: "0.1rem",
            fontSize: "0.875rem"
        }
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
        if (event.target.name === "continent") {
            setActiveFilters({
                id: "",
                name: "country"
            });
        }
        setActiveFilters({
            id: event.target.value,
            name: event.target.name
        });
    };

    const handInputleChange = ({ target }: BaseSyntheticEvent) => {
        setActiveFilters({
            id: target.id,
            name: "country"
        });

        setActiveFilters({
            id: "",
            name: "subregion"
        });
        target.id ? zoomToCountry(target.id) : handleResetMapFilters();
    };

    const handleReset = useCallback(() => {
        setActiveFilters({
            id: "all",
            name: "age"
        });
        setActiveFilters({
            id: "overall",
            name: "gender"
        });
        setActiveFilters({
            id: "all",
            name: "continent"
        });
    }, [setActiveFilters]);

    return (
        <>
            <article tw="grid grid-cols-[minmax(9rem, 25rem) minmax(9rem, 25rem) minmax(9rem, 25rem)] md:grid-cols-none sm:grid-cols-[calc(60% - 2rem) calc(40% - 2rem)] md:flex flex-col md:m-[1rem 0] xxs:grid-cols-[100%] sm:grid gap-[1rem] sm:items-end place-content-between m-[2rem 0 0 0] sm:px-[1rem] px-[4rem]">
                <div>
                    <div tw="flex items-center pb-[0.5rem]">
                        <h3 tw="uppercase font-medium text-xs">
                            {t("region_label")}
                        </h3>
                    </div>
                    <div tw="relative">
                        <img
                            tw="absolute top-[36%] px-[1rem]"
                            src={search}
                            alt="Search"
                        />
                        {map_section_data && (
                            <Autocomplete
                                clearIcon={<ClearIcon />}
                                sx={TextFieldStyle}
                                onChange={handInputleChange}
                                options={
                                    areSubRegionsActive
                                        ? map_section_data.filter(
                                              (country: Country) =>
                                                  subnationalCountriesName.includes(
                                                      country.id
                                                  )
                                          )
                                        : map_section_data
                                              .filter(
                                                  ({
                                                      filteredNumPeople
                                                  }: any) =>
                                                      typeof filteredNumPeople ===
                                                      "number"
                                              )
                                              .sort(
                                                  (
                                                      countryA: Country,
                                                      countryB: Country
                                                  ) =>
                                                      countryA.name.localeCompare(
                                                          countryB.name
                                                      )
                                              )
                                }
                                value={
                                    countryData
                                        ? { name: countryData.name }
                                        : (null as any)
                                }
                                autoHighlight
                                getOptionLabel={(option: Country) =>
                                    option.name
                                }
                                renderOption={(props: any, option: Country) => (
                                    <button
                                        {...props}
                                        id={option.iso3c}
                                        style={{
                                            color: "#0055CC",
                                            display: "flex",
                                            fontSize: "0.8rem",
                                            fontWeight: 700,
                                            gap: "0.65rem",
                                            width: "100%"
                                        }}
                                    >
                                        <img
                                            loading="lazy"
                                            width="16"
                                            src={option.file_url}
                                            alt={option.name}
                                        />
                                        {option.name}
                                    </button>
                                )}
                                renderInput={(params: any) => {
                                    const placeholder = t(
                                        "search_country_placeholder"
                                    ) as string;

                                    return (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                                placeholder
                                            }}
                                        />
                                    );
                                }}
                            />
                        )}
                    </div>
                </div>
                <CustomSelect
                    value={continent}
                    onChange={handleSelectChange}
                    name="continent"
                    tw="text-sm font-medium mt-[1.5rem] md:mt-[0] sm:hidden"
                >
                    {handleSelectOptions(continents, false)}
                </CustomSelect>
                <button
                    onClick={() => handleToggleState("isFiltersModalOpened")}
                    tw="hidden sm:flex items-center font-semiBold text-xs h-[2.5rem] gap-[0.625rem] p-[0.656rem 0.75rem] bg-purple-600 text-white rounded-[0.5rem] shadow border-none"
                >
                    <img src={filters} alt="Filters" />
                    {t("mobile_filters")}
                </button>
                <MobileFiltersModal
                    handleReset={handleReset}
                    handleToggleState={handleToggleState}
                    toggleState={toggleState}
                />
                <div>
                    <div tw="flex items-center mb-[0.5rem] uppercase font-medium text-xs">
                        <h3>{t("type_of_visualisation_label")}</h3>
                    </div>
                    <div tw="flex gap-[1rem]">
                        <div tw="rounded-[0.5rem] border-[1.5px] md:max-w-[30.18rem] sm:max-w-none h-[2.5rem] border-purple-600 flex items-center overflow-hidden text-purple-600 font-medium text-sm text-center">
                            {types.map(({ id, value }: Types) => (
                                <button
                                    key={id}
                                    css={[
                                        tw`w-[10rem] py-[0.625rem] border-r border-gray-400 hover:text-white hover:bg-purple-600 focus:bg-purple-600 focus:text-white last-of-type:border-0`,
                                        id === type_of_visualization &&
                                            tw`bg-purple-600 text-white`
                                    ]}
                                    onClick={() =>
                                        setActiveFilters({
                                            id,
                                            name: "type_of_visualization"
                                        })
                                    }
                                >
                                    {t(value)}
                                </button>
                            ))}
                        </div>
                        <button
                            onClick={handleReset}
                            tw="sm:hidden text-center text-white hover:text-white hover:bg-purple-800 bg-purple-600 focus:text-white focus:bg-purple-800 text-xs font-medium tracking-[0.055em] leading-[127.8%] w-full flex gap-[0.5rem] items-center justify-between p-[0.5rem] max-w-[8rem] h-[2.5rem] rounded-[0.5rem]"
                        >
                            <img alt="Reset" src={reset} />
                            {t("resets_filters")}
                        </button>
                    </div>
                </div>
                <MobileYearDropDown />
                {type_of_visualization === "ranking" && (
                    <div tw="md:block hidden">
                        <div tw="flex items-center mb-[0.5rem] uppercase font-medium text-xs">
                            <h3>{t("type_of_visualisation_ranking")}</h3>
                        </div>
                        <div tw="rounded-[0.5rem] border-[1.5px] md:max-w-none h-[2.5rem] border-purple-600 flex items-center overflow-hidden text-purple-600 font-medium text-sm text-center">
                            {rankingTypes.map(({ id, value }: Types) => (
                                <button
                                    key={id}
                                    disabled={
                                        areSubRegionsActive &&
                                        subnationalCountriesName.includes(
                                            country
                                        ) &&
                                        id === "internet_pricing"
                                    }
                                    css={[
                                        tw`w-[10rem] md:w-full py-[0.625rem] border-r border-gray-400 hover:text-white hover:bg-purple-600 focus:bg-purple-600 focus:text-white last-of-type:border-0`,
                                        id === ranking &&
                                            tw`bg-purple-600 text-white`,
                                        areSubRegionsActive &&
                                            subnationalCountriesName.includes(
                                                country
                                            ) &&
                                            id === "internet_pricing" &&
                                            tw`opacity-20 pointer-events-none`
                                    ]}
                                    onClick={() => {
                                        setActiveFilters({
                                            id,
                                            name: "ranking"
                                        });
                                        handleReset();
                                    }}
                                >
                                    {t(value)}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </article>
            {(!country || subnationalCountriesName.includes(country)) && (
                <article tw="p-[0.5rem 4rem 1rem 4rem] flex justify-between items-center w-[24rem] sm:px-[1rem] sm:pb-0 sm:w-[18rem]">
                    <ToggleSwitch
                        tw="hidden"
                        type="checkbox"
                        id="subNational"
                        checked={areSubRegionsActive}
                        onChange={(e) => {
                            setActiveFilters({
                                id: areSubRegionsActive,
                                name: "areSubRegionsActive"
                            });
                            if (e.target.checked) {
                                setActiveFilters({
                                    id: "all",
                                    name: "age"
                                });
                                setActiveFilters({
                                    id: "overall",
                                    name: "gender"
                                });
                                setActiveFilters({
                                    id: "",
                                    name: "subregion"
                                });
                            }
                        }}
                    />
                    <ToggleSwitchLabel htmlFor="subNational" />
                    <label
                        tw="text-blue-800 cursor-pointer"
                        htmlFor="subNational"
                    >
                        {t("subnational_label")}
                    </label>
                </article>
            )}
            <article
                css={[
                    tw`grid grid-cols-[minmax(9rem, 25rem) minmax(9rem, 25rem) minmax(9rem, 25rem)] md:grid-cols-none md:flex flex-col sm:grid-cols-[calc(60% - 2rem) calc(40% - 2rem)] xxs:grid-cols-[100%] sm:grid gap-[1rem] sm:items-end place-content-between m-[0 0 2rem 0] md:m-0 md:mb-[2rem] sm:px-[1rem] px-[4rem]`,
                    country &&
                        !subnationalCountriesName.includes(country) &&
                        tw`mt-4`
                ]}
            >
                <div tw="sm:hidden">
                    <div tw="flex items-center mb-[0.5rem] uppercase font-medium text-xs">
                        <h3
                            css={[
                                isInternetPricingActive(
                                    type_of_visualization,
                                    ranking
                                ) && tw`opacity-20 pointer-events-none`
                            ]}
                        >
                            {t("gender_label")}
                        </h3>
                    </div>
                    <div tw="rounded-[0.5rem] border-[1.5px] border-purple-600 flex items-center sm:hidden overflow-hidden text-purple-600 font-medium text-sm text-center">
                        {genders.map(({ id, value }: Types) => (
                            <button
                                key={id}
                                css={[
                                    tw`w-[10rem] lg:w-[8rem] lg:text-xs md:text-sm md:w-full py-[0.625rem] border-r border-gray-400 last:border-0 hover:text-white hover:bg-purple-600 focus:bg-purple-600 focus:text-white`,
                                    id === gender &&
                                        tw`bg-purple-600 text-white`,
                                    isInternetPricingActive(
                                        type_of_visualization,
                                        ranking
                                    ) ||
                                        (areSubRegionsActive &&
                                            subnationalCountriesName.includes(
                                                country
                                            ) &&
                                            tw`opacity-20 pointer-events-none`)
                                ]}
                                disabled={
                                    isInternetPricingActive(
                                        type_of_visualization,
                                        ranking
                                    ) ||
                                    (areSubRegionsActive &&
                                        subnationalCountriesName.includes(
                                            country
                                        ))
                                }
                                onClick={() =>
                                    setActiveFilters({
                                        id,
                                        name: "gender"
                                    })
                                }
                            >
                                {t(value)}
                            </button>
                        ))}
                    </div>
                </div>
                <div tw="sm:hidden">
                    <div tw="flex items-center mb-[0.5rem] uppercase font-medium text-xs">
                        <h3
                            css={[
                                isInternetPricingActive(
                                    type_of_visualization,
                                    ranking
                                ) && tw`opacity-20 pointer-events-none`
                            ]}
                        >
                            {t("age_group_label")}
                        </h3>
                    </div>
                    <CustomSelect
                        value={age}
                        disabled={
                            isInternetPricingActive(
                                type_of_visualization,
                                ranking
                            ) ||
                            (areSubRegionsActive &&
                                subnationalCountriesName.includes(country))
                        }
                        onChange={handleSelectChange}
                        name="age"
                        tw="text-sm sm:text-xs w-full"
                    >
                        {handleSelectOptions(
                            ages,
                            isInternetPricingActive(
                                type_of_visualization,
                                ranking
                            )
                        )}
                    </CustomSelect>
                </div>
                {type_of_visualization === "ranking" && (
                    <div tw="md:hidden">
                        <div tw="flex items-center mb-[0.5rem] uppercase font-medium text-xs">
                            <h3>{t("type_of_visualisation_ranking")}</h3>
                        </div>
                        <div tw="rounded-[0.5rem] border-[1.5px] sm:max-w-none h-[2.5rem] border-purple-600 flex items-center overflow-hidden text-purple-600 font-medium text-sm text-center">
                            {rankingTypes.map(({ id, value }: Types) => (
                                <button
                                    key={id}
                                    disabled={
                                        areSubRegionsActive &&
                                        subnationalCountriesName.includes(
                                            country
                                        ) &&
                                        id === "internet_pricing"
                                    }
                                    css={[
                                        tw`w-full py-[0.625rem] border-r border-gray-400 hover:text-white hover:bg-purple-600 focus:bg-purple-600 focus:text-white last-of-type:border-0`,
                                        id === ranking &&
                                            tw`bg-purple-600 text-white`,
                                        areSubRegionsActive &&
                                            subnationalCountriesName.includes(
                                                country
                                            ) &&
                                            id === "internet_pricing" &&
                                            tw`opacity-20 pointer-events-none`
                                    ]}
                                    onClick={() => {
                                        setActiveFilters({
                                            id,
                                            name: "ranking"
                                        });
                                        handleReset();
                                    }}
                                >
                                    {t(value)}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </article>
        </>
    );
};

export default Filters;
