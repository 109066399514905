import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";
import { MarksPair } from "interfaces/Slider.interface";
import { slidermarks } from "utils/Slider";
import { t } from "i18next";
import { useInternetPoverty } from "context/internetPoverty";

const CustomSelect = styled(Select)<any>(() => ({
    ".MuiInputBase-input": {
        color: theme`colors.purple.600`,
        padding: "0 1rem",
        height: "calc(2.5rem - 3px) !important",
        border: `solid 1.5px ${theme`colors.purple.600`}`,
        borderRadius: "0.5rem",
        display: "flex",
        alignItems: "center"
    },
    ".MuiSelect-icon": {
        color: theme`colors.purple.600`
    }
}));

const MobileYearDropDown = () => {
    const { year, setActiveFilters } = useInternetPoverty();

    const handleChange = (event: SelectChangeEvent) =>
        setActiveFilters({ id: event.target.value, name: "year" });

    const options = slidermarks.map(({ value }: MarksPair) => (
        <MenuItem
            key={value}
            value={value}
            tw="w-full bg-white border border-b-gray-300 text-sm"
        >
            {value}
        </MenuItem>
    ));

    return (
        <div tw="sm:flex flex-col hidden gap-[0.5rem]">
            <h3 tw="font-medium text-xs uppercase">{t("year")}</h3>
            <CustomSelect value={year} onChange={handleChange}>
                {options}
            </CustomSelect>
        </div>
    );
};

export default MobileYearDropDown;
