import { css } from "@emotion/react";
import styled from "@emotion/styled";
import tw, { theme } from "twin.macro";

export const ToggleSwitchLabel = styled.label([
    tw`w-[2rem] h-[1rem] rounded-[2.125rem] flex bg-gray-400 cursor-pointer items-center p-[0.1rem]`,
    css`
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 0.8rem;
            height: 0.8rem;
            background: ${theme`colors.white`};
            transition: 0.2s;
        }
    `
]);

export const ToggleSwitch = styled.input([
    tw`w-[2rem] h-[0.75rem]`,
    css`
        &:checked + label {
            background: ${theme`colors.blue.600`};
            transition: 0.2s;

            &::after {
                content: "";
                display: block;
                margin-left: 1rem;
            }
        }
    `
]);
