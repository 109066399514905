import React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { slidermarks } from "utils/Slider";
import { useInternetPoverty } from "context/internetPoverty";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const StyledSlider = styled(Slider)(() => ({
    ".MuiSlider-mark": {
        borderRadius: 50,
        height: "1rem",
        width: "1rem",
        backgroundColor: theme`colors.purple`
    },
    ".MuiSlider-markLabel": {
        color: theme`colors.gray`,
        fontSize: "1rem",
        fontWeight: 400
    },
    ".MuiSlider-markLabelActive": {
        fontSize: "1rem",
        fontWeight: 500,
        color: theme`colors.black`
    },
    ".MuiSlider-rail": {
        color: theme`colors.purple`,
        height: "1px",
        opacity: 1
    },
    ".MuiSlider-thumb": {
        height: "32px",
        width: "32px",
        backgroundColor: theme`colors.blue`,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
    },
    ".MuiSlider-markActive": {
        color: theme`colors.black`
    }
}));

const YearSlider = () => {
    const { setActiveFilters, year } = useInternetPoverty();

    const handleChange = (_: Event, value: number | number[]): void =>
        setActiveFilters({ id: value, name: "year" });

    return (
        <div tw="w-[calc(100% - 1rem)] py-[4rem] sm:hidden px-[4rem]">
            <StyledSlider
                track={false}
                getAriaLabel={() => "Year slider marks"}
                getAriaValueText={(value) => value.toString()}
                marks={slidermarks}
                min={2015}
                max={2024}
                onChange={handleChange}
                value={year}
                tw="m-0 p-0"
            />
        </div>
    );
};

export default YearSlider;
