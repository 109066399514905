import { t } from "i18next";
import { handleFinalData, handleFinalPercentageData } from "utils/Country";
import { useInternetPoverty } from "context/internetPoverty";
import closeButton from "assets/images/countryDetailsClose.svg";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ClearIcon from "components/Filters/ClearIcon";
import arrow from "assets/images/arrow.svg";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";
import { formatNumber } from "utils/Data";
import { subnationalCountriesName } from "utils/Items";

const CountryDetails = ({ handleResetMapFilters, zoomToCountry }: any) => {
    const {
        age,
        country,
        countryData,
        countrySubRegionData,
        gender,
        areSubRegionsActive,
        map_section_data,
        subregion,
        setActiveFilters
    } = useInternetPoverty();

    const TextFieldStyle = {
        "& .MuiAutocomplete-clearIndicator": {
            marginRight: "2rem !important",
            visibility: "visible !important"
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "unset !important"
        },

        "& .MuiAutocomplete-input": {
            borderRadius: "0.5rem",
            color: theme`colors.purple.600`,
            fontWeight: 400
        },
        "& .MuiAutocomplete-popupIndicator svg": {
            display: "none"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0
        },
        "& .MuiTextField-root": {
            background: `url(${arrow}) no-repeat 100%`,
            backgroundPosition: "right 1rem center",
            border: "1px solid #353F87",
            borderRadius: "0.5rem"
        },
        "& .MuiOutlinedInput-root": {
            paddingLeft: "0.6rem",
            paddingRight: "0.25rem",
            paddingTop: "0.1rem",
            paddingBottom: "0.1rem",
            fontSize: "0.875rem"
        }
    };

    const finalData =
        subregion && countrySubRegionData ? countrySubRegionData : countryData;

    const handleInputChange = (newValue: string, reason: string) => {
        setActiveFilters({
            id: reason === "clear" ? "" : newValue,
            name: "subregion"
        });
        zoomToCountry(reason === "clear" ? country : newValue, true);
    };

    return (
        <aside tw="p-[1.25rem 2rem 1.25rem 1.25rem] top-0 z-[1002] absolute bg-white flex flex-col gap-[1rem] right-0 w-full border border-blue-800 text-gray-700 shadow h-full max-w-[33rem] lg:max-w-[25rem] md:fixed md:rounded-none md:max-w-none md:border-0 rounded-[0.5rem 0px 0px 0.5rem] md:max-h-full md:z-[999]">
            <div tw="flex items-center justify-between z-[1000]">
                <img width={24} src={finalData?.file_url} alt="Flag" />
                <button onClick={handleResetMapFilters}>
                    <img
                        tw="absolute right-[1.25rem] top-[1.25rem]"
                        src={closeButton}
                        alt="Close"
                    />
                </button>
            </div>
            <h3 tw="text-xxl font-bold">
                {finalData?.country.name === "C\u00f4te d?Ivoire"
                    ? "C\u00f4te d’Ivoire"
                    : finalData?.country.name}
            </h3>
            {areSubRegionsActive && (
                <div>
                    <h3 tw="uppercase font-medium text-xs mb-[0.5rem]">
                        {t("sub_region")}
                    </h3>
                    <Autocomplete
                        clearIcon={<ClearIcon />}
                        sx={TextFieldStyle}
                        onChange={(_, value, reason) =>
                            handleInputChange(value!, reason)
                        }
                        value={subregion ? subregion : null}
                        options={
                            map_section_data && map_section_data.length <= 1
                                ? map_section_data[0].features
                                : map_section_data
                        }
                        autoHighlight
                        getOptionLabel={(option: any) =>
                            option.properties ? option.properties.name : option
                        }
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                    placeholder: t(
                                        "subregion_placeholder"
                                    ) as string
                                }}
                            />
                        )}
                        renderOption={(props: any, option: any) => (
                            <button
                                {...props}
                                style={{
                                    color: "#0055CC",
                                    display: "flex",
                                    fontSize: "0.8rem",
                                    fontWeight: 700,
                                    gap: "0.65rem",
                                    width: "100%"
                                }}
                            >
                                {option.properties
                                    ? option.properties.name
                                    : option}
                            </button>
                        )}
                    />
                </div>
            )}
            <div
                className="scrollable"
                tw="flex flex-col gap-[1rem] overflow-auto h-full pr-[1rem]"
            >
                <div
                    className="scrollable"
                    tw="flex flex-col gap-[1rem] overflow-auto h-full pr-[1rem]"
                >
                    <div tw="rounded-[0.5rem] border border-gray-300 p-[1rem] flex flex-col gap-[0.5rem]">
                        {age !== "all" ? (
                            <span>
                                {t(
                                    "country_selected_total_population_title_age_group"
                                )}{" "}
                                {t(age)}:
                            </span>
                        ) : (
                            t("country_selected_total_population_title")
                        )}
                        <p tw="font-bold text-[1.75rem] leading-[1.75rem]">
                            {finalData &&
                                handleFinalData(
                                    age,
                                    finalData,
                                    "overall",
                                    "totalPopulation"
                                ).toLocaleString()}
                        </p>
                    </div>
                    <div tw="rounded-[0.5rem] border border-gray-300 p-[1rem] flex flex-col gap-[0.5rem]">
                        <h4 tw="font-semiBold uppercase leading-[1.25rem]">
                            {t(
                                "country_selected_number_of_internet_poor_title"
                            )}
                        </h4>
                        <div tw="flex gap-[0.25rem] justify-between items-center lg:flex-wrap">
                            <p tw="font-bold text-[1.75rem] leading-[1.75rem] text-purple-600">
                                {finalData &&
                                    handleFinalData(
                                        age,
                                        finalData,
                                        "overall",
                                        "internetPoorPeople"
                                    ).toLocaleString()}
                            </p>
                            <span tw="lg:hidden h-full border-r border-gray-400"></span>
                            <p tw="text-gray-600 text-sm">
                                <span tw="text-purple-600 font-bold leading-[1.75rem]">
                                    {finalData &&
                                        formatNumber(
                                            parseFloat(
                                                handleFinalPercentageData(
                                                    age,
                                                    finalData,
                                                    gender,
                                                    gender
                                                )
                                                    .toFixed(1)
                                                    .toLocaleString()
                                            )
                                        )}
                                    %
                                </span>{" "}
                                {t("country_selected_total_population")}
                            </p>
                        </div>
                    </div>
                    {areSubRegionsActive &&
                    subnationalCountriesName.includes(country) &&
                    subregion ? null : (
                        <div tw="rounded-[0.5rem] flex flex-col gap-[0.5rem] border border-gray-300 p-[1rem]">
                            <h4 tw="font-semiBold uppercase leading-[1.25rem] pb-[0.5rem]">
                                {t("country_selected_poverty_gender_title")}
                            </h4>
                            <div css={[gender === "males" && tw`hidden`]}>
                                <h5 tw="font-semiBold">
                                    {t("country_selected_female_title")}
                                </h5>
                                <p tw="text-gray-600 text-sm mt-[0.5rem]">
                                    {t(
                                        "country_selected_total_female_population_subtitle"
                                    )}
                                </p>
                                <p tw="font-bold text-[1.75rem] leading-[1.75rem] mb-[0.5rem]">
                                    {finalData &&
                                        handleFinalData(
                                            age,
                                            finalData,
                                            "females",
                                            "totalPopulation"
                                        ).toLocaleString()}
                                </p>
                                <p tw="text-gray-600 text-sm">
                                    {t(
                                        "country_selected_female_in_internet_poverty_subtitle"
                                    )}
                                </p>
                                <div tw="flex justify-between items-center lg:flex-wrap">
                                    <p tw="font-bold text-[1.75rem] leading-[1.75rem] text-purple-600 py-[0.5rem]">
                                        {finalData &&
                                            handleFinalData(
                                                age,
                                                finalData,
                                                "females",
                                                "internetPoorPeople"
                                            ).toLocaleString()}
                                    </p>
                                    <span tw="lg:hidden h-full border-r border-gray-300"></span>
                                    <div tw="flex flex-col">
                                        <p tw="text-gray-600 text-sm">
                                            <span tw="text-purple-600 font-bold leading-[1.75rem]">
                                                {finalData &&
                                                    formatNumber(
                                                        parseFloat(
                                                            handleFinalPercentageData(
                                                                age,
                                                                finalData,
                                                                gender,
                                                                gender
                                                            )
                                                                .toFixed(1)
                                                                .toLocaleString()
                                                        )
                                                    )}
                                                %
                                            </span>{" "}
                                            {t(
                                                "country_selected_total_population"
                                            )}
                                        </p>
                                        <p tw="text-gray-600 text-sm">
                                            <span tw="text-purple-600 font-bold leading-[1.75rem]">
                                                {finalData &&
                                                    formatNumber(
                                                        parseFloat(
                                                            handleFinalPercentageData(
                                                                age,
                                                                finalData,
                                                                "females",
                                                                "overall"
                                                            )
                                                                .toFixed(1)
                                                                .toLocaleString()
                                                        )
                                                    )}
                                                %
                                            </span>{" "}
                                            {t(
                                                "country_selected_total_females_subtitle"
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div css={[gender === "females" && tw`hidden`]}>
                                <h5 tw="font-semiBold">
                                    {t("country_selected_male_title")}
                                </h5>
                                <p tw="text-gray-600 text-sm mt-[0.5rem]">
                                    {t(
                                        "country_selected_total_male_population_subtitle"
                                    )}
                                </p>
                                <p tw="font-bold text-[1.75rem] leading-[1.75rem] mb-[0.5rem]">
                                    {finalData &&
                                        handleFinalData(
                                            age,
                                            finalData,
                                            "males",
                                            "totalPopulation"
                                        ).toLocaleString()}
                                </p>
                                <p tw="text-gray-600 text-sm">
                                    {t(
                                        "country_selected_males_in_internet_poverty_subtitle"
                                    )}
                                </p>
                                <div tw="flex justify-between items-center lg:flex-wrap">
                                    <p tw="font-bold text-[1.75rem] leading-[1.75rem] text-purple-600  py-[0.5rem]">
                                        {finalData &&
                                            handleFinalData(
                                                age,
                                                finalData,
                                                "males",
                                                "internetPoorPeople"
                                            ).toLocaleString()}
                                    </p>
                                    <span tw="lg:hidden h-full border-r border-gray-300"></span>
                                    <div tw="flex flex-col">
                                        <p tw="text-gray-600 text-sm">
                                            <span tw="text-purple-600 font-bold leading-[1.75rem]">
                                                {finalData &&
                                                    formatNumber(
                                                        parseFloat(
                                                            handleFinalPercentageData(
                                                                age,
                                                                finalData,
                                                                gender,
                                                                gender
                                                            )
                                                                .toFixed(1)
                                                                .toLocaleString()
                                                        )
                                                    )}
                                                %
                                            </span>{" "}
                                            {t(
                                                "country_selected_total_population"
                                            )}
                                        </p>
                                        <p tw="text-gray-600 text-sm">
                                            <span tw="text-purple-600 font-bold leading-[1.75rem]">
                                                {finalData &&
                                                    formatNumber(
                                                        parseFloat(
                                                            handleFinalPercentageData(
                                                                age,
                                                                finalData,
                                                                "males",
                                                                "overall"
                                                            )
                                                                .toFixed(1)
                                                                .toLocaleString()
                                                        )
                                                    )}
                                                %
                                            </span>{" "}
                                            {t(
                                                "country_selected_total_males_subtitle"
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {areSubRegionsActive &&
                    subnationalCountriesName.includes(country) &&
                    subregion ? null : (
                        <div tw="rounded-[0.5rem] border border-gray-300 p-[1rem] flex flex-col gap-[0.5rem]">
                            <h4 tw="font-semiBold uppercase leading-[1.25rem]">
                                {t("country_selected_internet_price_title")}
                            </h4>
                            <p tw="font-bold text-[1.75rem] leading-[1.75rem] text-purple-600 py-[0.5rem]">
                                ${" "}
                                {finalData?.internetPrice
                                    .toFixed(1)
                                    .toLocaleString()}
                            </p>
                            <h4 tw="font-semiBold uppercase leading-[1.25rem]">
                                {t("country_selected_big_byte_index_title")}
                            </h4>
                            <p tw="text-gray-600 text-sm">
                                <span
                                    css={[
                                        tw`font-bold text-[1.75rem] leading-[1.75rem] text-purple-600 py-[0.5rem]`,
                                        finalData?.bigByteIndex < 100
                                            ? tw`text-green`
                                            : finalData?.bigByteIndex === 100
                                            ? tw`text-purple-500`
                                            : tw`text-red`
                                    ]}
                                >
                                    {finalData?.bigByteIndex
                                        .toFixed(1)
                                        .toLocaleString()}
                                </span>{" "}
                                {finalData?.bigByteIndex < 100 ? (
                                    <span tw="text-green">
                                        {t(
                                            "country_selected_under_valued_subtitle"
                                        )}
                                    </span>
                                ) : finalData?.bigByteIndex === 100 ? (
                                    t("country_selected_base_valued_subtitle")
                                ) : (
                                    <span tw="text-red">
                                        {t(
                                            "country_selected_over_valued_subtitle"
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </aside>
    );
};

export default CountryDetails;
