import { t } from "i18next";

export const handleReduceDataIds = (
    array: any[],
    handleId: (elem: any) => string
) =>
    array.reduce((obj: any, item: any) => {
        obj[handleId(item)] = item;
        return obj;
    }, {});
let rankingName: string | null | undefined = "poverty";

export const formatNumber = (value: number, ranking?: null | string )  => {
    rankingName = ranking;
    return(
        value < 3 && rankingName !== "internet_pricing"
            ? t("below")
            : value.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
        })
    );
}
