import React from "react";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useInternetPoverty } from "context/internetPoverty";

const Legend = () => {
    const { age, gender } = useInternetPoverty();

    return (
        <article tw="md:mt-[2rem] border border-purple-800 rounded-[0.5rem] md:w-[calc(100% - 8rem)] md:max-w-none md:relative p-[0.75rem 1rem] max-w-[11rem] w-[calc(100% - 2rem)] sm:max-w-none flex flex-col gap-[0.625rem] sm:mx-[1rem] sm:left-0 absolute bottom-0 left-[4rem] sm:w-[calc(100% - 2rem)] z-[401] bg-white shadow">
            {age === "all" && gender === "overall" ? (
                <h4 tw="text-sm sm:text-xs text-blue-700">
                    {t("map_legend_title")}
                </h4>
            ) : (
                <h4 tw="text-sm sm:text-xs text-blue-700">
                    Share of{" "}
                    {gender === "overall" ? (
                        t("people")
                    ) : (
                        <span>{t(gender).toLowerCase()}</span>
                    )}
                    {age !== "all" && <span> aged {t(age).toLowerCase()}</span>}{" "}
                    {t("map_legend_poverty")}
                </h4>
            )}
            <div tw="flex flex-wrap justify-evenly gap-[0.625rem] text-sm">
                <div tw="border w-full border-gray-300 sm:w-[calc(50% - 0.5rem)] rounded-[0.5rem] p-[0.2rem 0.5rem] flex items-center gap-[0.5rem] text-gray-600">
                    <div tw="w-[0.625rem] h-[0.625rem] bg-blue-200 rounded-[0.5rem]"></div>
                    <span>0-20%</span>
                </div>
                <div tw="border w-full border-gray-300 sm:w-[calc(50% - 0.5rem)] rounded-[0.5rem] p-[0.2rem 0.5rem] flex items-center gap-[0.5rem] text-gray-600">
                    <div tw="w-[0.625rem] h-[0.625rem] bg-blue-500 rounded-[0.5rem]"></div>
                    <span>20-40%</span>
                </div>
                <div tw="border w-full border-gray-300 sm:w-[calc(50% - 0.5rem)] rounded-[0.5rem] p-[0.2rem 0.5rem] flex items-center gap-[0.5rem] text-gray-600">
                    <div tw="w-[0.625rem] h-[0.625rem] bg-blue-600 rounded-[0.5rem]"></div>
                    <span>40-60%</span>
                </div>
                <div tw="border w-full border-gray-300 sm:w-[calc(50% - 0.5rem)] rounded-[0.5rem] p-[0.2rem 0.5rem] flex items-center gap-[0.5rem] text-gray-600">
                    <div tw="w-[0.625rem] h-[0.625rem] bg-blue-700 rounded-[0.5rem]"></div>
                    <span>60-80%</span>
                </div>
                <div tw="border w-full border-gray-300 sm:w-[calc(50% - 0.5rem)] rounded-[0.5rem] p-[0.2rem 0.5rem] flex items-center gap-[0.5rem] text-gray-600">
                    <div tw="w-[0.625rem] h-[0.625rem] bg-blue-800 rounded-[0.5rem]"></div>
                    <span>80-100%</span>
                </div>
            </div>
        </article>
    );
};

export default Legend;
