import React from "react";
import Socials from "components/Footer/Socials";
import Supporter from "components/Footer/Supporter";
import footerLogo from "assets/images/footer/footerLogo.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Footer = () => (
    <footer tw="bg-purple-800 text-white">
        <div tw="sm:p-[1rem] p-[2rem 4rem 3rem] text-white gap-[4rem] sm:gap-[3rem] max-w-[90rem] mx-auto grid grid-cols-[repeat(3, minmax(9rem, 21rem))] lg:grid-cols-[minmax(9rem, 21rem) minmax(9rem, 21rem)] md:grid-cols-none items-center place-content-between">
            <div tw="flex flex-col gap-[1rem] text-sm">
                <h2 tw="leading-[28px] font-bold text-xl sm:text-lg">
                    Internet Poverty Index
                </h2>
                <span tw="sm:text-xs">by</span>
                <div tw="flex items-center gap-[0.75rem] tracking-[0.17em]">
                    <img src={footerLogo} alt="Footer internet poverty" />
                    <span>WORLD DATA LAB</span>
                </div>
            </div>
            <Socials />
            <Supporter />
        </div>
    </footer>
);

export default Footer;
