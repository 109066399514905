import closeFiltersButton from "assets/images/closeFilters.svg";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useInternetPoverty } from "context/internetPoverty";
import { t } from "i18next";
import reset from "assets/images/reset.svg";
import {
    genders,
    ages,
    continents,
    subnationalCountriesName
} from "utils/Items";
import { Types } from "interfaces/Types.interface";
import { styled } from "@mui/material/styles";
import { isInternetPricingActive } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const CustomSelect = styled(Select)<any>(() => ({
    ".MuiInputBase-input": {
        color: theme`colors.black`,
        padding: "0 1rem",
        height: "calc(2.5rem - 3px) !important",
        borderRadius: "0.5rem",
        display: "flex",
        alignItems: "center",
        backgroundColor: theme`colors.white`,
        letterSpacing: "0.055em",
        lineHeight: "127.8%",
        fontSize: theme`fontSize.xs`
    },
    ".MuiSelect-icon": {
        color: theme`colors.purple.700`
    }
}));

const FiltersModal = ({ handleReset, handleToggleState, toggleState }: any) => {
    const {
        age,
        areSubRegionsActive,
        country,
        continent,
        gender,
        setActiveFilters,
        type_of_visualization,
        ranking
    } = useInternetPoverty();

    const handleSelectOptions = (type: Types[]) =>
        type.map(({ id, value }: Types) => (
            <MenuItem
                key={id}
                value={id}
                css={[
                    tw`text-xs`,
                    (value === "select_continent" ||
                        value === "all_age_groups") &&
                        tw`hidden`
                ]}
            >
                {t(value)}
            </MenuItem>
        ));

    const handleSelectChange = (event: SelectChangeEvent) =>
        setActiveFilters({
            id: event.target.value,
            name: event.target.name
        });

    return (
        <aside
            css={[
                tw`hidden flex-col left-0 justify-between fixed w-full h-[100vh] z-[1001] top-0 bg-purple-800 p-[1rem 1rem 4rem] items-center`,
                toggleState.isFiltersModalOpened && tw`sm:flex`
            ]}
        >
            <div tw="flex flex-col gap-[1.5rem] w-full">
                <div tw="flex items-center justify-between mb-[4rem] font-semiBold text-xs text-white leading-[127.8%]">
                    <h3>{t("filters")}</h3>
                    <button
                        onClick={() =>
                            handleToggleState("isFiltersModalOpened")
                        }
                    >
                        <img src={closeFiltersButton} alt="Filters close" />
                    </button>
                </div>
                <div>
                    <h4 tw="text-white font-regular text-xs tracking-[0.15em] leading-[1.25rem] uppercase pb-[0.5rem]">
                        {t("select_continent")}
                    </h4>
                    <CustomSelect
                        value={continent}
                        onChange={handleSelectChange}
                        tw="text-xs flex"
                        name="continent"
                    >
                        {handleSelectOptions(continents)}
                    </CustomSelect>
                </div>
                <div>
                    <h4 tw="text-white font-regular text-xs tracking-[0.15em] leading-[1.25rem] uppercase pb-[0.5rem]">
                        {t("gender_label")}
                    </h4>
                    <div tw="flex items-center justify-between gap-[1rem]">
                        {genders.map(({ id, value }: Types) => (
                            <button
                                key={id}
                                css={[
                                    tw`w-[33.3333%] bg-white rounded-[0.5rem] text-black text-xs font-medium tracking-[0.055em] leading-[127.8%] h-[2.188rem] text-center hover:bg-purple-500 hover:text-white`,
                                    id === gender &&
                                        tw`bg-purple-600 text-white`,
                                    isInternetPricingActive(
                                        type_of_visualization,
                                        ranking
                                    ) ||
                                        (areSubRegionsActive &&
                                            subnationalCountriesName.includes(
                                                country
                                            ) &&
                                            tw`opacity-20 pointer-events-none`)
                                ]}
                                disabled={
                                    isInternetPricingActive(
                                        type_of_visualization,
                                        ranking
                                    ) ||
                                    (areSubRegionsActive &&
                                        subnationalCountriesName.includes(
                                            country
                                        ))
                                }
                                onClick={() => {
                                    setActiveFilters({
                                        id,
                                        name: "gender"
                                    });
                                }}
                            >
                                {t(value) === "All genders" ? "All" : t(value)}
                            </button>
                        ))}
                    </div>
                </div>
                <div>
                    <h4 tw="text-white font-regular text-xs tracking-[0.15em] leading-[1.25rem] uppercase pb-[0.5rem]">
                        {t("age_group_label")}
                    </h4>
                    <CustomSelect
                        value={age}
                        onChange={handleSelectChange}
                        tw="text-xs flex"
                        name="age"
                        disabled={
                            isInternetPricingActive(
                                type_of_visualization,
                                ranking
                            ) ||
                            (areSubRegionsActive &&
                                subnationalCountriesName.includes(country))
                        }
                    >
                        {handleSelectOptions(ages)}
                    </CustomSelect>
                </div>
            </div>
            <div tw="flex flex-col max-w-[9rem] w-full gap-[1.5rem]">
                <button
                    onClick={() => handleToggleState("isFiltersModalOpened")}
                    tw="text-center text-black bg-white text-xs font-medium tracking-[0.055em] leading-[127.8%] h-[2.188rem] rounded-[0.5rem]"
                >
                    {t("back_to_the_index")}
                </button>
                <button
                    onClick={handleReset}
                    tw="flex items-center justify-between p-[1rem] text-center text-white bg-[#181F39] text-xs font-medium tracking-[0.055em] leading-[127.8%] h-[2.188rem] rounded-[0.5rem]"
                >
                    <img alt="Reset" src={reset} />
                    {t("resets_filters")}
                </button>
            </div>
        </aside>
    );
};

export default FiltersModal;
