import { useCallback, useEffect, useState } from "react";

const useFetch = (url: string, skip = false) => {
    const [data, setData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchData = useCallback(async () => {
        if (skip) return;
        setIsLoading(true);
        try {
            const response = await fetch(url);
            const result = await response.json();
            if (response.ok) {
                setData(result);
                setHasError(false);
            } else {
                setHasError(true);
                setErrorMessage(result);
            }
        } catch (err: any) {
            setHasError(true);
            setErrorMessage(err.message);
        } finally {
            setIsLoading(false);
        }
    }, [url, skip]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const clearData = useCallback(() => {
        setData(null);
    }, []);

    return { data, isLoading, hasError, errorMessage, clearData };
};

export default useFetch;
