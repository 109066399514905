import React, { useEffect, useState } from "react";
import { t } from "i18next";
import DynamicSvg from "components/DynamicSVG/DynamicSVG";
import { useInternetPoverty } from "context/internetPoverty";
import ScrollToMap from "components/ScrollToMap";
import { Types } from "interfaces/Types.interface";
import { genders } from "utils/Items";
import FlipNumbers from "react-flip-numbers";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const HeroSection = () => {
    const {
        continent,
        gender,
        hero_section_data,
        map_section_data,
        setActiveFilters
    } = useInternetPoverty();
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const value =
        continent === "world" || continent === "all"
            ? hero_section_data[gender]?.value.toLocaleString("en-US") ===
              undefined
                ? ""
                : hero_section_data[gender]?.value.toLocaleString("en-US")
            : map_section_data &&
              map_section_data
                  .filter((country: any) => country.filteredNumPeople)
                  .reduce(
                      (acc: number, curr: any) =>
                          acc + curr.population[gender].internetPoorPeople,
                      0
                  )
                  .toLocaleString("en-US");

    const handleWindowResize = () => {
        setWindowSize(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const styles = {
        color: theme`colors.white`,
        fontSize: windowSize <= 768 ? "3rem" : "6rem",
        fontWeight: 900,
        fontFamily: "Work Sans"
    };

    return (
        <section tw="bg-purple-800 h-[calc(100vh - 8.75rem)] sm:h-[calc(100vh - 6.75rem)] text-center text-white flex flex-col justify-center relative">
            <div tw="sm:hidden absolute w-full">
                <DynamicSvg iconName="heroSectionBackground" />
            </div>
            <div tw="sm:block hidden absolute w-full">
                <DynamicSvg iconName="heroSectionBackgroundMobile" />
            </div>
            <FlipNumbers
                height={windowSize <= 768 ? 40 : 122}
                width={windowSize <= 768 ? 30 : 70}
                color={theme`colors.white`}
                play
                perspective={100000}
                numbers={`${value}`}
                numberStyle={styles}
                nonNumberStyle={styles}
            />
            <h2 tw="tracking-[0.14em] text-lg font-regular leading-[149.8%] uppercase">
                {t("number_of_people_living_text")}{" "}
                <strong>{t("internet_poverty_text")}</strong>
            </h2>
            <h3 tw="pb-[1rem]">
                {continent === "world" || continent === "all"
                    ? ""
                    : continent.toUpperCase()}
            </h3>
            <div tw="gap-[1.5rem] flex justify-center p-[1rem]">
                {genders.map(({ id, value }: Types) => (
                    <button
                        key={id}
                        css={[
                            tw`bg-white text-purple-800 py-[0.625rem] px-[0.688rem] rounded-[0.5rem] text-center w-[12.125rem] text-sm sm:text-xs focus:bg-purple-600 focus:text-white hover:bg-purple-600 hover:text-white border-none uppercase z-[1]`,
                            id === gender && tw`bg-purple-600 text-white`
                        ]}
                        onClick={() =>
                            setActiveFilters({
                                id,
                                name: "gender"
                            })
                        }
                    >
                        {t(value)}
                    </button>
                ))}
            </div>
            <ScrollToMap />
        </section>
    );
};

export default HeroSection;
